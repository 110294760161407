import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Controller } from "react-hook-form";
import { Col, Form, OverlayTrigger, Popover } from "react-bootstrap";
import zxcvbn from 'zxcvbn'; 
import { generateRandomPassword } from "../helper/RegisterConstant";


const PasswordSection = ({
  control,
  errors,
  setError,
  clearErrors,
  watch,
  isColSixRequired = false,
  invalidFieldRequired = false,
  setValue,
  developerId
}) => {
  const { t } = useTranslation();
  const [isPassword, setPassword] = useState({
    firstPass: false,
    secondPass: false,
  });
  // const [password, setPassword] = useState('');
  const [strength, setStrength] = useState(null);
  const [suggestedPassword, setSuggestedPassword] = useState('');

  const evaluateStrength = (pwd) => {
    const evaluation = zxcvbn(pwd);
    console.log(evaluation,"evaluation")
    setStrength(evaluation);
  };

  const generateStrongPassword = () => {
    const strongPwd = generateRandomPassword()
    console.log(strongPwd,"strongPwd")
    setSuggestedPassword(strongPwd);
    setValue('password',strongPwd)
    evaluateStrength(strongPwd);
  }

  const handleChangePassword = (value, type) => {
    const password = watch("password");
    console.log(password,"passwe")
    const confirmPassword = watch("confirm_password");
    if (type === "password" && password!==undefined ) {
      evaluateStrength(password);
      if (value === confirmPassword) {
        if (confirmPassword.length) {
          clearErrors("confirm_password");
        }
      } else {
        if (confirmPassword?.length) {
          setError("confirm_password", {
            type: "manual",
            message: "password and confirm password must match",
          });
        }
      }
    }
  };

  const getColorForStrength = (score) => {
    switch (score) {
      case 0: return 'red'; // Weak
      case 1: return 'orange'; // Fair
      case 2: return 'yellow'; // Good
      case 3: return 'blue'; // Strong
      case 4: return 'green'; // Very Strong
      default: return 'gray'; // Default color
    }
  };

  const popoverFocus = (
    <Popover id="popover-trigger-focus" title="Popover top">
      <div className="p-3"> <button  className="outline-main-btn px-4 font-14 mr-2" onClick={generateStrongPassword}>Suggest Strong Password</button></div>
    </Popover>
  );

  console.log(strength,"strength")
  return (
    <>
      {isColSixRequired ? (
        <>
          <Col md={6} className="pe-md-3 pe-0">
            <div className="mb-3">
              <Form.Label className="common-label">{t("Password")} *</Form.Label>
              <OverlayTrigger
      trigger="click"
      rootClose
      placement="top"
      overlay={popoverFocus}
    >
         <div className="position-relative">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: !developerId ?  "Password is required" : false,
                    pattern: {
                      value: /^.{8,12}$/,
                      message: "Password must be between 8 to 12 characters",
                    },
                  }}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      placeholder="Enter Password"
                      type={isPassword?.firstPass ? "text" : "password"}
                      onChange={(e) => {
                        handleChangePassword(e.target.value, "password");
                        field.onChange(e);
                      }}
                      className={`common-field ${(invalidFieldRequired && errors?.password?.message) && "invalid-field"}`}
                      // id='developer-image'
                    />
                  )}
                />
                <span
                  className="eye-btn"
                  onClick={() => {
                    setPassword({
                      ...isPassword,
                      firstPass: !isPassword?.firstPass,
                    });
                  }}
                >
                  {isPassword?.firstPass ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
       </OverlayTrigger>
           
              {errors?.password && (
                <p className={`${ (invalidFieldRequired) ? "field-error" : "error-message"}`}>{errors?.password?.message}</p>
              )}
            </div>
           
            {strength && (
        <div>
          <p className="text-white">Password Strength: {['Weak', 'Fair', 'Good', 'Strong', 'Very Strong'][strength.score]}</p>
          {/* <div style={{ width: '30%', height: '20px', backgroundColor: '#e0e0e0', borderRadius: '5px', margin: '10px 0' }}>
            <div
              style={{
                width: `${(strength.score + 1) * 20}%`, // Maps score to percentage
                height: '100%',
                backgroundColor: getColorForStrength(strength.score),
                borderRadius: '5px',
                transition: 'width 0.5s ease-in-out',
              }}
            ></div>
          </div> */}
          {strength?.feedback?.suggestions?.length > 0 && (
            <ul>
              {strength?.feedback?.suggestions?.map((suggestion, index) => (
                <li className="text-white" key={index}>{suggestion}</li>
              ))}
            </ul>
          )}
        </div>
      )}
          </Col>
          <Col md={6} className="pe-md-3 pe-0">
            <div className="mb-3">
              <Form.Label className="common-label">
                {t("Confirm Password")} *
              </Form.Label>
              <div className="position-relative">
                <Controller
                  control={control}
                  name="confirm_password"
                  rules={{
                    required: !developerId ? "Confirm password is required" : false,
                    validate: (value) =>
                      value === watch("password") ||
                      "Password and confirm password must match",
                  }}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      placeholder="Confirm password"
                      type={isPassword?.secondPass ? "text" : "password"}
                      onChange={(e) => {
                        handleChangePassword(
                          e.target.value,
                          "confirm_password"
                        );
                        field.onChange(e);
                      }}
                      className={`common-field ${(invalidFieldRequired && errors?.confirm_password?.message) && "invalid-field"}`}
                      // id='developer-image'
                    />
                  )}
                />
                <span
                  className="eye-btn"
                  onClick={() => {
                    setPassword({
                      ...isPassword,
                      secondPass: !isPassword?.secondPass,
                    });
                  }}
                  aria-label={
                    isPassword?.secondPass ? "Hide password" : "Show password"
                  }
                >
                  {isPassword?.secondPass ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {errors?.confirm_password && (
                <p className={`${ (invalidFieldRequired) ? "field-error" : "error-message"}`}>
                  {errors?.confirm_password?.message}
                </p>
              )}
            </div>
          </Col>
        </>
      ) : (
        <>
          <Form.Group className="mb-3">
            <Form.Label className="common-label">{t("Password")}</Form.Label>
            <div className="position-relative">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message:
                      "Password must contain at least a symbol, upper and lower case letters and a number",
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type={isPassword?.firstPass ? "text" : "password"}
                    onChange={(e) => {
                      handleChangePassword(e.target.value, "password");
                      field.onChange(e);
                    }}
                    className={`common-field`}
                    // id='developer-image'
                  />
                )}
              />
              <span
                className="eye-btn"
                onClick={() => {
                  setPassword({
                    ...isPassword,
                    firstPass: !isPassword?.firstPass,
                  });
                }}
                // aria-label={
                //   isPassword?.firstPass ? "Hide password" : "Show password"
                // }
              >
                {isPassword?.firstPass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors?.password && (
              <p className="error-message">{errors?.password?.message}</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="common-label">
              {t("Confirm Password")}
            </Form.Label>
            <div className="position-relative">
              <Controller
                control={control}
                name="confirm_password"
                rules={{
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watch("password") ||
                    "Password and confirm password must match",
                }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type={isPassword?.secondPass ? "text" : "password"}
                    onChange={(e) => {
                      handleChangePassword(e.target.value, "confirm_password");
                      field.onChange(e);
                    }}
                    className={`common-field`}
                    // id='developer-image'
                  />
                )}
              />
              <span
                className="eye-btn"
                onClick={() => {
                  setPassword({
                    ...isPassword,
                    secondPass: !isPassword?.secondPass,
                  });
                }}
                aria-label={
                  isPassword?.secondPass ? "Hide password" : "Show password"
                }
              >
                {isPassword?.secondPass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors?.confirm_password && (
              <p className="error-message">
                {errors?.confirm_password?.message}
              </p>
            )}
          </Form.Group>
        </>
      )}
    </>
  );
};

export default PasswordSection;