import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaUpload } from "react-icons/fa6";
import { IoClose, IoPlay } from "react-icons/io5";
import IntroVideo from "../common/Modals/IntroVideo";
import videoImg from "../assets/images/demo-video.png";

const UploadFile = ({
  label,
  placeholder,
  register,
  fieldName,
  previewImage,
  setPreviewImage,
  imageFile,
  setImageFile,
  setValue,
  watch,
  clearErrors,
  errors,
  setResumeUrl,
  setError,
  stepData,
}) => {
  console.log(watch("resume"),"log2 resume fieldValue");
  console.log(imageFile,"log2 imageFile");
  const [showVideo, setShowVideo] = useState(false);
  const DOC_ALLOWED_EXTENSIONS = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const VIDEO_ALLOWED_EXTENSIONS = ["video/mp4", "video/webm", "video/ogg"];

  const handleFileChange = async (e, name) => {
    const file = e.target.files[0];
    const isImage = DOC_ALLOWED_EXTENSIONS.includes(file?.type);
    const isVideo = VIDEO_ALLOWED_EXTENSIONS.includes(file?.type);

    if (file) {
      if (name === "resume" && isImage) {
        const url = URL.createObjectURL(file);
        setPreviewImage((prev) => ({ ...prev, resume: url }));
        setImageFile((prev) => ({ ...prev, resume: file }));
        setResumeUrl(url)
        clearErrors(fieldName);
      } else if (name === "certificate" && isImage) {
        const url = URL.createObjectURL(file);
        setPreviewImage((prev) => ({ ...prev, certificate: url }));
        setImageFile((prev) => ({ ...prev, certificate: file }));
        // setResumeUrl(url)
        clearErrors(fieldName);
      } else if (name == "intro_video_url" && isVideo) {
        const url = URL.createObjectURL(file);
        setPreviewImage((prev) => ({ ...prev, introVideo: url }));
        setImageFile((prev) => ({ ...prev, introVideo: file }));
        clearErrors(fieldName);
      } else {
        setValue(fieldName, null);
        setError(fieldName, {
          type: "manual",
          message: `Please enter a valid file type: ${
            name === "resume" || name === "certificate" ? "pdf,msword" : "mp4, webm, ogg"
          }`,
        });
        setPreviewImage(null);
      }
    }
  };

  const handleIntroVideo = () => {
    setShowVideo((prev) => !prev);
  };

  const handleClear = () => {
    setImageFile((prevState) => ({ ...prevState, resume: "" }));
    setValue("resume", "");
  };

  const handleClearCertificate = () => {
    setImageFile((prevState) => ({ ...prevState, certificate: "" }));
    setValue("certificate", "");
  };

  const handleClearIntro = () => {
    setImageFile((prevState) => ({ ...prevState, introVideo: "" }));
    setValue("intro_video_url", "");
  };

  // const handleValidation = () => {
  //   if (label == "Resume") {
  //     const returnValue = imageFile?.resume ? false : "Resume is required";
  //     return returnValue;
  //   } else {
  //     return false;
  //   }
  // };

  const handleValidation = () => {
    if (label === "Resume") {
      return imageFile?.resume ? false : "Resume is required";
    } else if (label === "Certificate") {
      return imageFile?.certificate ? false : "Certificate is required";
    }
    return false;
  };

  const isResumeEmpty = imageFile?.resume === "" || imageFile?.resume == null;
  const isCertificateEmpty = imageFile?.certificate === "" || imageFile?.certificate == null;
  const isIntroVideoEmpty =
    imageFile?.introVideo === "" || imageFile?.introVideo == null;
  const getLabel = () => {
    return (
      <>
        <Form.Label htmlFor={fieldName} className="upload-intro-file">
          {placeholder}
        </Form.Label>
      </>
    );
  };

  return (
    // <>
    //   <div className="mb-3">
    //     <Form.Label className="font-14 fw-medium">
    //       {label} {label === "Resume" || label === "Certificate" ? "*" : ""}
    //     </Form.Label>
    //     <Form.Control
    //       {...register(fieldName, {
    //         required: handleValidation(),
    //         onChange: (e) => {
    //           handleFileChange(e, fieldName);
    //         },
    //       })}
    //       type="file"
    //       id={fieldName}
    //       placeholder="Company Name"
    //       className="common-field d-none"
    //     />
    //     {/* <Form.Label htmlFor={fieldName} className="upload-intro-file">
    //     {placeholder}
    //   </Form.Label> */}
    //   </div>
    //   <div>
    //     {/* {errors[fieldName] && (
    //       <p className="field-error">{errors[fieldName]?.message}</p>
    //     )} */}
    //     {label !== "Resume" ? (
    //       isIntroVideoEmpty ? (
    //         getLabel()
    //       ) : (
    //         <div className="profile-upload-preview position-relative preview_intro mb-3">
    //           <div className="profile-img-preview w-100 h-100">
    //             <video
    //               src={
    //                 previewImage?.introVideo ||
    //                 stepData?.intro_video_url ||
    //                 videoImg
    //               }
    //               className="w-100 h-100"
    //               controls
    //             />
    //           </div>
    //           <div className="playback_intro" onClick={handleIntroVideo}>
    //             <IoPlay />
    //           </div>
    //           <span
    //             className="cursor-pointer text-danger"
    //             onClick={handleClearIntro}
    //           >
    //             <IoClose />
    //           </span>
    //           <Form.Label htmlFor="intro-video" className="profile-img-label">
    //             <FaUpload />
    //           </Form.Label>
    //         </div>
    //       )
    //     ) : (
    //       <div>
    //         {isResumeEmpty ? (
    //           getLabel()
    //         ) : (
    //           <div className="d-flex justify-content-between align-items-center gap-5 p-2 bg-light rounded-3 mb-3">
    //             <span className="font-14 fw-medium">
    //               {imageFile?.resume?.name || stepData?.resume}
    //             </span>
    //             <span
    //               className="cursor-pointer text-danger"
    //               onClick={handleClear}
    //             >
    //               <IoClose />
    //               {/* {errors[fieldName] && (
    //                 <p className="field-error">{errors[fieldName]?.message}</p>
    //               )} */}
    //             </span>
    //           </div>
    //         )}
    //       </div>
    //     )}
    //     {errors[fieldName] && (
    //       <p className="field-error">{errors[fieldName]?.message}</p>
    //     )}
    //   </div>
    //   <IntroVideo
    //     show={showVideo}
    //     handleClose={handleIntroVideo}
    //     previewImage={
    //       previewImage?.introVideo
    //         ? previewImage?.introVideo
    //         : stepData?.intro_video_url
    //     }
    //   />
    // </>
    <>
      <div className="mb-3">
        <Form.Label className="font-14 fw-medium">
          {label} {label === "Resume" || label === "Certificate" ? "*" : ""}
        </Form.Label>
        <Form.Control
          {...register(fieldName, {
            required: handleValidation(),
            onChange: (e) => handleFileChange(e, fieldName),
          })}
          type="file"
          id={fieldName}
          placeholder="Upload"
          className="common-field d-none"
        />
      </div>
      <div>
        {label === "Intro Video" ? (
          isIntroVideoEmpty ? (
            getLabel()
          ) : (
            <div className="profile-upload-preview position-relative preview_intro mb-3">
              <div className="profile-img-preview w-100 h-100">
                <video
                  src={
                    previewImage?.introVideo ||
                    stepData?.intro_video_url ||
                    videoImg
                  }
                  className="w-100 h-100"
                  controls
                />
              </div>
              <div className="playback_intro" onClick={handleIntroVideo}>
                <IoPlay />
              </div>
              <span
                className="cursor-pointer text-danger"
                onClick={() => handleClear("introVideo")}
              >
                <IoClose />
              </span>
              <Form.Label htmlFor="intro-video" className="profile-img-label">
                <FaUpload />
              </Form.Label>
            </div>
          )
        ) : label === "Resume" ? (
          isResumeEmpty ? (
            getLabel()
          ) : (
            <div className="d-flex justify-content-between align-items-center gap-5 p-2 bg-light rounded-3 mb-3">
              <span className="font-14 fw-medium">
                {imageFile?.resume?.name || stepData?.resume}
              </span>
              <span
                className="cursor-pointer text-danger"
                onClick={() => handleClear("resume")}
              >
                <IoClose />
              </span>
            </div>
          )
        ) : label === "Certificate" ? (
          isCertificateEmpty ? (
            getLabel()
          ) : (
            <div className="d-flex justify-content-between align-items-center gap-5 p-2 bg-light rounded-3 mb-3">
              <span className="font-14 fw-medium">
                {imageFile?.certificate?.name || stepData?.certificate_key}
              </span>
              <span
                className="cursor-pointer text-danger"
                onClick={() => handleClearCertificate("certificate")}
              >
                <IoClose />
              </span>
            </div>
          )
        ) : null}
        {errors[fieldName] && (
          <p className="field-error">{errors[fieldName]?.message}</p>
        )}
      </div>
      <IntroVideo
        show={showVideo}
        handleClose={handleIntroVideo}
        previewImage={
          previewImage?.introVideo
            ? previewImage?.introVideo
            : stepData?.intro_video_url
        }
      />
    </>
  );
};

export default UploadFile;
