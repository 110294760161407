import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVerification } from '../Redux/Slices/ClientDataSlice';
import { getToken } from '../constant/utils';
import { setClientActiveStep,setDeveloperId } from '../Redux/Slices/DeveloperDataSlice';
import { setVendorActiveStep } from '../Redux/Slices/VendorDataSlice';

const EmailVerification = () => {
    const { search } = useLocation();
    const token = search?.split("=")[1];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (token) {
            dispatch(getVerification(token, (response) => {
                console.log('hello from email verification component result123', response);
                if (response.data.success) {
                  // dispatch(setClientActiveStep(2));
                  // dispatch(setDeveloperId());
                  if(response?.data?.data?.role === 'developer'){
                    dispatch(setClientActiveStep(2));
                    navigate('/talent-registration');
                  }
                  else if(response?.data?.data?.role === 'client'){
                    dispatch(setClientActiveStep(2));
                    navigate('/client-registration');
                  }
                  else if(response?.data?.data?.role === 'vendor'){
                    dispatch(setVendorActiveStep(2));
                    navigate('/partner-registration');
                  }
                }
                setLoading(false);
            }));
        } else {
            setLoading(false);
        }
    }, [dispatch, navigate, token]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {/* You can add any additional UI or messages here if needed */}
        </div>
    );
};

export default EmailVerification;
