
function ScreenLoader() {
    return (
      <>
        <div>
          <section className="loading-screen">
            <span className="loader-wrapper"></span>
          </section>
        </div>
      </>
    );
  }
  
  export default ScreenLoader;
  